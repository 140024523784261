import React, { useContext, useState } from "react";
import PropTypes from "prop-types";
import { defaults } from "react-chartjs-2";

import {
	Accordion,
	Card,
	useAccordionToggle,
	AccordionContext,
	Button,
} from "react-bootstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faMinus } from "@fortawesome/free-solid-svg-icons";

import { MDBRow, MDBCol } from "mdbreact";

export default function Itinerarios({ itinerarios }) {
	Itinerarios.propTypes = {
		itinerarios: PropTypes.array.isRequired,
	};

	// State para zerar buttons
	const [statusAllButtons, setStatusAllButtons] = useState(0);

	// Gera os conjuntos de itinerários
	let conjuntos = groupBy(itinerarios, (item) => item.semestre);

	// Ordena itinerario ordem alfabetica
	conjuntos.forEach((semestre) => {
		semestre[1].sort((a, b) => a.title.localeCompare(b.title));
	});

	// Inicia array para os dados do gráfico radar
	let dataRadar = {
		labels: [
			"Comunicação",
			"Tecnologia",
			"Desenvolvimento pessoal",
			"Empreendedorismo",
			"Criatividade",
		],
		datasets: [],
	};

	// Inicia array para os dados do gráfico de barras
	let dataBar = {
		labels: [
			"Linguagens",
			"Ciências Humanas",
			"Ciências da Natureza",
			"Matemática",
		],
		datasets: [],
	};

	//Configuração global para os graficos não terem legenda
	defaults.global.legend.display = false;

	/** Função de groupBy para array's, instruções a baixo.
	 * @description
	 * Takes an Array<V>, and a grouping function,
	 * and returns a Map of the array grouped by the grouping function.
	 *
	 * @param list An array of type V.
	 * @param keyGetter A Function that takes the the Array type V as an input, and returns a value of type K.
	 *                  K is generally intended to be a property key of V.
	 *
	 * @returns Map of the array grouped by the grouping function.
	 *
	 * //export function groupBy<K, V>(list: Array<V>, keyGetter: (input: V) => K): Map<K, Array<V>> {
	 * //    const map = new Map<K, Array<V>>();*/
	function groupBy(list, keyGetter) {
		const map = new Map();
		list.forEach((item) => {
			const key = keyGetter(item);
			const collection = map.get(key);
			if (!collection) {
				map.set(key, [item]);
			} else {
				collection.push(item);
			}
		});
		return Array.from(map);
	}

	// Lógica relativa aos toggles para expandir
	function ContextAwareToggle({ children, eventKey, callback }) {
		const currentEventKey = useContext(AccordionContext);
		const decoratedOnClick = useAccordionToggle(
			eventKey,
			() => callback && callback(eventKey)
		);
		const isCurrentEventKey = currentEventKey === eventKey;

		// Ao clicar no accordeon esta funcion troca o icon e zera os gráficos
		const onClick = (event) => {
			decoratedOnClick(event);

			dataRadar.datasets = [];
			dataBar.datasets = [];
		};

		return (
			<>
				<Button
					className="w-100 pb-2"
					variant="link"
					onClick={() => {
						onClick(), setStatusAllButtons(statusAllButtons + 1);
					}}
				>
					<span>
						<h4 className="fgterciary-gradient pt-1 pr-1 text-lowercase font-italic h5 float-left">
							{children}
						</h4>
						{isCurrentEventKey || eventKey === 1 ? (
							<FontAwesomeIcon
								icon={faMinus}
								className="float-right mr-3 pt-2 h3"
							/>
						) : (
							<FontAwesomeIcon
								icon={faPlus}
								className="float-right mr-3 pt-2 h3"
							/>
						)}
					</span>
				</Button>
			</>
		);
	}

	// Lógica relativa aos botões
	function ButtonToggleContext({ itinerario, indexChildren, children }) {
		// State para o primeiro button

		const [first, setFirst] = useState(true);

		const [status, setStatus] = useState(false);

		function statusHandler() {
		}

		return (
			<>
				<button
					className="btn btn-block rounded-0 border-0 h-100 p-1 m-0"
					style={{
						textTransform: "initial",
						backgroundColor: itinerario.color,
					}}
					onClick={() => {
						statusHandler();
					}}
				>
					{indexChildren == 0 ? (
						<div
							className={
								(statusAllButtons == 0
									? first
										? "bg-white colorLetter"
										: "bg-transparent text-white"
									: first
									? "bg-white colorLetter"
									: "bg-transparent text-white") + " p-4 h-100"
							}
						>
							{children}
						</div>
					) : (
						<div
							className={
								(status
									? "bg-transparent text-white"
									: "bg-white colorLetter") + " p-4 h-100"
							}
						>
							{children}
						</div>
					)}
				</button>
			</>
		);
	}

	return (
		<>
			
			<Accordion defaultActiveKey="0" className="pt-2 pb-2 pb-md-4">
				{conjuntos?.map((c, indexFather) => {
					return (
						<Card key={indexFather}>
							<Card.Header className="p-0 m-0 accordion-header">
								<ContextAwareToggle
									eventKey={indexFather == 0 ? "0" : `"${indexFather}"`}
								>
									{"itinerários " + c[0]}
								</ContextAwareToggle>
							</Card.Header>
							<Accordion.Collapse
								eventKey={indexFather == 0 ? "0" : `"${indexFather}"`}
							>
								<Card.Body>
									<MDBRow>
										{c[1].map((itinerario, indexChildren) => {
											return (
												<MDBCol
													col={12}
													md={6}
													className="py-2"
													key={indexChildren}
												>
													<ButtonToggleContext
														itinerario={itinerario}
														indexChildren={indexChildren}
													>
														<h5>{itinerario.title}</h5>
														<p className="m-0 p-0 text-center">
															{"// " + itinerario.description}
															<div
																dangerouslySetInnerHTML={{
																	__html: "//" + itinerario.date,
																}}
															></div>
															{itinerario.turma && (
																<div
																dangerouslySetInnerHTML={{
																	__html: "//" + itinerario.turma,
																}}
															></div>
															)}
															
															{itinerario.compartilhado == "x" && (
																<p className="m-0 p-0 text-center">
																	<br />
																	<b>Este itinerario é compartilhado</b> com
																	o(s) curso(s) de{" "}
																	{itinerario.cursoCompartilhado}.
																</p>
															)}
														</p>
													</ButtonToggleContext>
												</MDBCol>
											);
										})}
									</MDBRow>
								</Card.Body>
							</Accordion.Collapse>
						</Card>
					);
				})}
			</Accordion>
		</>
	);
}
